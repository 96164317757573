@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.card {
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.search-box{
  display: inline-flex !important; 
  margin-left: 15px;
  margin-bottom:10px;
}
.button_pos{
  position: absolute;
  right: 0;
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  margin-bottom: 20px;
}

.logo-image,.cover-image {
  height: 272px;
  border-radius: 8px;
  width: 300px;
}

.logo-image {
  object-fit: contain;
}

.remove-image{
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  background: #e71000;
  padding: 4px;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  color: #fff;
}

.remove-pdf{
  position: absolute;
  top: 0px;
  z-index: 2;
  background: #e71000;
  padding: 2px;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  color: #fff;
  margin-left: 5px;
}

.new_box{ position: relative; margin: auto;}

.pdf_box{ position: relative; margin-bottom: 30px;}

.pdf_box a{ color: #e71000;}

.error-message {
  color: #e71000;
}

@media screen and (max-width: 1920px) {
  .css-u6kgca {
      overflow-x: scroll !important;
  }
}

@media screen and (max-width: 960px) {
  .css-u6kgca {
      overflow-x: scroll !important;
  }
}

.css-4p6x0h {
  padding-bottom: 20px;
}


.overlay_loader_bg{background-color: #00000075;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

  .loader_img img{ 
    text-align: center; margin: auto; width: 80px; padding-top: 20%;
  }

  .loader {
    height: 100vh;
    position: fixed;
    z-index: 3000;
    top: 0;
    left: 0;
    background: #0000004f;
    width: 100%;
  }
  
  .spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-top: 8px solid #000;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
    text-align: center; margin: auto;margin-top: 20%;
  }

  .property-priority-input{
    width: 80px !important;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }

  .ck-editor__editable {
    min-height: 500px !important;
}


.p-reorderable-column{ padding-left:20px;}
.p-datatable-tbody tr { border-bottom: 1px solid #e2e8f0;}
.p-datatable-thead{border-bottom: 1px solid #e2e8f0; height: 60px;}
.p-datatable-tbody tr td{ padding: 20px; color: #1b2559;
  font-size: var(--chakra-fontSizes-md);
  font-weight: 500;}
.p-reorderable-column{ text-transform: uppercase; font-size: 12px; color: #a0aec0; font-family: var(--chakra-fonts-heading); letter-spacing: var(--chakra-letterSpacings-wider);}

.search-title, .search-box{
  display: inline-block;
}

.dev-search-box input{
  padding-left: 10px;
}

.search-box .p-icon-field{
  border: 1px solid #666;
  padding: 6px;
  border-radius: 10px;
}

.search-box input:focus-visible{
  outline: none;
}

.note {
  font-size: 13px;
  color: #ff08d5;
  padding: 14px 28px;
  background: #ffedfc;
  border-radius: 10px;
}

.col-1box{ padding: 20px 25px; background: #f5f4fb; color: #000; display: inline-block; margin: 15px; width: 340px; height: 110px; padding-top: 27px;border-radius: 15px;}
.col-1box h2{ color: #FF00D8; font-size: 22px; font-weight: bold;} 
.col-1box p{ font-size: 13px; padding-top: 10px;} 

.css-hg1g3k {
  padding-inline-start: 20px;
}
.css-1ii2pep {
  text-align: left;
}


.file-up-img{ display: inline-block;
  margin: 10px;
  border: 1px solid #000;
  padding: 6px;
  border-radius: 6px;
  background: #f1f1f1;}
.pdf-file-up-img{ display: inline-block; margin: 15px;filter:none; }
.css-k0waxj{ display: none !important;}
.css-1tp1co5 { width: 50% !important; }

.container {
  width: 750px;
}
.medical-file-dropzone {
  width: 650px;
  height: 100px;
}
.medical-file-preview-image{
  object-fit: cover !important;
  height: 65px !important;
}

.input_container {
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  gap: 10px;
}

.medical-file-name{
  height: 65px !important;
}

.add-row{
  background: #000;
  color: #fff;
  padding: 20px;
  height: 40px;
  border-radius: 10px;
  width: 100%;
  line-height: 2px;
  margin-bottom: 20px;
}

.delete-row{
  background: #d12a1e;
  color: #fff;
  padding: 20px;
  height: 40px;
  border-radius: 10px;
  width: 100%;
  line-height: 2px;
}

.medical-file-preview{ position: relative;}

.remove-medical-file{
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  background: #e71000;
  padding: -4px;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  color: #fff;
  font-size: 10px;
}